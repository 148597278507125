<div class="modal-header">
  <h4 class="modal-title">
    <span *ngIf="!reloading">Preview of the '{{quiz.title}}' {{quizTypeName|lowercase}}</span>
    <span *ngIf="reloading">Preview of the {{quizTypeName|lowercase}}</span>
  </h4>
</div>

<div class="modal-body quiz-modal-preview">
  <div *ngIf="!reloading">
    <p *ngIf="quiz.introductoryText" [innerHtml]="quiz.introductoryText | markdownToHtml"></p>
    <div *ngFor="let quizQuestion of quiz.questions; let index=index">
      <div class="panel panel-default question-panel standard-margin-top">
        <div class="panel-heading question-heading">Question {{index+1}} of {{quiz.questions.length}}</div>
        <div class="panel-body">
          <quiz-player-question [question]="quizQuestion.question" [scoreTypeId]="quiz.scoreTypeId"></quiz-player-question>

          <div *ngIf="quiz.resultsReviewAllowed">
            <div *ngIf="quiz.showCorrectQuestionFeedback !== 0 && quizQuestion.question.correctFeedback">
              <div class="row col-xs-12 font-bold xs-margin-bottom">Correct Feedback
                <span style="font-weight: normal; font-style: italic;" *ngIf="quiz.showCorrectQuestionFeedback === 2">(Only on the final attempt)</span>
              </div>
              <div class="col-xs-12">
                <div class="question-choice">
                  <div class="alert alert-warning">
                    <span [innerHtml]="quizQuestion.question.correctFeedback|markdownToHtml"></span>
                  </div>
                </div>
              </div>
            </div>
            <div class="" *ngIf="quiz.showIncorrectQuestionFeedback !== 0 && quizQuestion.question.incorrectFeedback">
              <div class="row col-xs-12 font-bold xs-margin-bottom">Incorrect Feedback
                <span style="font-weight: normal; font-style: italic;" *ngIf="quiz.showIncorrectQuestionFeedback === 2">(Only on the final attempt)</span>
              </div>

              <div class="col-xs-12">
                <div class="question-choice">
                  <div class="alert alert-warning">
                    <span [innerHtml]="quizQuestion.question.incorrectFeedback|markdownToHtml"></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
    <div *ngIf="!quiz.questions?.length" class="standard-margin-top row">
        <div class="col-xs-12 col-sm-6">
            <div class="alert alert-warning">There are no questions defined for this {{quizTypeName|lowercase}}</div>
        </div>
    </div>
    <p *ngIf="quiz.finalText" [innerHtml]="quiz.finalText | markdownToHtml"></p>
  </div>
  <div *ngIf="reloading && !previewError">
    <div class="load-spinner"></div>
  </div>
  <div class="alert alert-sm alert-danger" *ngIf="previewError">{{previewError}}</div>
</div>

<div class="modal-footer">
  <a class="btn btn-warning pull-right" (click)="closeModal()">Close</a>
</div>
