<div>
  <!--Search panel-->
  <div class="row standard-margin-bottom">
    <fieldset [disabled]="searchRequestPromise" class="no-margin-bottom">
      <div class="col-sm-9 col-xs-12 standard-margin-bottom-phone" [ngClass]="{'col-sm-12':!filters.questionTypes}">
        <form class="input-group no-margin-bottom" (ngSubmit)="applySearch()">
          <input type="text" class="form-control" placeholder="Search question by keyword" title="Search" [(ngModel)]="query" autoFocus name="query"
                 (keydown.enter)="applySearch()">

          <span class="input-group-btn">
              <button type="submit" (click)="applySearch()" class="btn btn-default"
                      data-spinner-color="#000" [ladda]="!!searchRequestPromise" aria-label="Search" title="Search">
                <span class="fa fa-search" aria-hidden="true"></span>
              </button>
            </span>
        </form>
      </div>
      <div class="col-sm-3 col-xs-12" *ngIf="filters.questionTypes">
        <select *ngIf="filters.questionTypes" id="questionType" class="form-control" [(ngModel)]="questionType"
                name="questionType" (ngModelChange)="applySearch();">
          <option [value]="null">All types</option>
          <option *ngFor="let questionType of questionTypes" [ngValue]="questionType">{{questionType.name}}</option>
        </select>
      </div>

      <div class="col-sm-12 col-xs-12 standard-margin-top">
        <div class="flex flex-vertical-center">
          <div class="flex-row">
            <div class="flex-col flex-col-grow well well-sm no-margin-bottom" >
              <div>
                <span *ngIf="itemCount >= 0">{{itemCount|number}} record(s) found.</span>
                <a href="#" class="display-inline-block" *ngIf="filters.active"
                   title="Show/hide inactive questions"
                   (click)="showInactive = !showInactive; applySearch();">
                  {{ showInactive ? 'Hide inactive' : 'Show inactive'}}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </fieldset>
  </div>

  <quiz-category-search-filter (changeHandler)="categoryChanged($event)" [useHandler]="true"></quiz-category-search-filter>

  <!--Search Result-->
  <div *ngIf="itemCount">
    <ul class="list-group">
      <!--Header-->
      <li class="list-group-header font-bold hidden-xs">
        <div class="flex">
          <div class="flex-row">
            <div class="flex-col flex-col-grow">
              <div>Question</div>
            </div>
            <div class="flex-col-10x flex-col">
              Type
            </div>
          </div>
        </div>
      </li>

      <!--Question list-->
      <li class="list-group-item" *ngFor="let item of items" [ngClass]="{'use-ban-cursor': item.selected}">
        <div class="flex" [ngClass]="{'disabled-row': item.selected || searchRequestPromise}">
          <div class="flex-row">
            <div class="flex-col flex-col-grow text-truncate ">
              <a href="#" (click)="addQuestion(item)"
                 class="text-default no-text-decoration inline-block full-width">
                <div class="xs-margin-bottom visible-xs pull-right" *ngIf="!item.active">
                  <span class="label label-danger label-sm" style="line-height: initial;" >Inactive</span>
                </div>
                <div class="text-truncate" [innerHtml]="item.title"></div>
                <div *ngIf="item.categoryName" class="text-muted">
                  {{item.categoryName}}
                </div>
                <div *ngIf="!item.active">
                  <span class="label label-danger label-sm"  >Inactive</span>
                </div>
                <div class="xs-margin-top visible-xs">
                  {{item.typeName}}
                </div>
              </a>
            </div>
            <div class="flex-col flex-col-10x hidden-xs standard-margin-left">
              <span >{{item.typeName}}</span>
            </div>
          </div>
        </div>
      </li>
    </ul>

    <div class="text-center" >
      <ngb-pagination *ngIf="itemCount > itemsPerPage" [collectionSize]="itemCount" [maxSize]="itemsPerPage"
                      [rotate]="true" [ellipses]="false" (pageChange)="pageChanged($event)" [boundaryLinks]="true"
                      [disabled]="!!searchRequestPromise" [page]="currentPage" size="sm">
        <ng-template ngbPaginationFirst>&laquo;</ng-template>
        <ng-template ngbPaginationLast>&raquo;</ng-template>
        <ng-template ngbPaginationPrevious>&lsaquo;</ng-template>
        <ng-template ngbPaginationNext>&rsaquo;</ng-template>
        <ng-template ngbPaginationNumber let-page>{{page}}</ng-template>
      </ngb-pagination>
    </div>
  </div>
</div>
