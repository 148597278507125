<div class="modal-header">
  <button type="button" class="close" aria-label="Close" (click)="closeModal()">
    <span aria-hidden="true">&times;</span>
  </button>
    <h4 class="modal-title">Move questions</h4>
</div>

<div class="modal-body">
  <p>This question cannot be moved because it has conditions added.</p>
</div>

<div class="modal-footer">
  <fieldset  class="no-margin-bottom">
    <button type="button" class="btn btn-success" (click)="closeModal()">OK</button>
  </fieldset>
</div>
