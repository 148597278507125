import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { IQuizOptions } from 'modules/admin/quiz/models/quiz.model';
import { IQuestion } from 'modules/quiz/models/quiz.model';
import {
  NewQuizQuestionVersionPromptComponent
} from 'modules/admin/quiz/question/components/modal/question-version-prompt.component';


@Injectable()
export class QuizQuestionsVersionManagerService {
  constructor(
    private http: HttpClient,
    private ngbModal: NgbModal,
  ) { }

  getQuestionVersionChain(questionId: number) {
    return this.http.get<IQuizOptions>(['/a/quizzes/questions/', questionId, '/versions/'].join(''));
  }

  showNewVersionPrompt(question: IQuestion) {
    const modalRef = this.ngbModal.open(NewQuizQuestionVersionPromptComponent);

    modalRef.componentInstance.question = question;

    return modalRef.result;
  }
}
