<form name="editQuestionForm"
      ngForm="editQuestionForm" #editQuestionForm="ngForm" (ngSubmit)="submit(editQuestionForm.form)"
      id="editQuestionForm">
  <div class="modal-header">
    <button type="button" class="close" aria-label="Close" (click)="cancel()"><span aria-hidden="true">&times;</span></button>
    <h4 class="modal-title">{{questionDraft.question.typeExtensionName || questionDraft.question.typeName}} question</h4>
  </div>
  <div class="modal-body modal-body-overflow">
    <div  *ngIf="isQuestionEditable || quiz.scoreTypeId" class="form-horizontal">

      <edit-quiz-question [question]="questionDraft.question" *ngIf="isQuestionEditable"
                          [emitter]="emitter"
        [formOptions]="editQuestionForm" ></edit-quiz-question>

      <!--Weight-->
      <div *ngIf="quiz.scoreTypeId" class="form-group standard-margin-bottom sm-margin-top"
           [ngClass]="{'has-error':questionWeight.invalid && (editQuestionForm.submitted || questionWeight.dirty)}">
        <label class="col-sm-3 control-label" for="questionWeightEdit">Question Weight</label>
        <div class="col-sm-4">
          <input id="questionWeightEdit"
                 name="questionWeight"
                 type="number"
                 required
                 class="form-control required"
                 [(ngModel)]="questionDraft.weight"
                 #questionWeight="ngModel"
                 min="0"
                 max="9999999" valid-positive-integer>
          <p class="help-block"
             *ngIf="questionWeight.invalid && (editQuestionForm.submitted || questionWeight.dirty)">
            <span *ngIf="questionWeight.errors?.required">Question Weight is required.</span>
            <span *ngIf="questionWeight.errors?.integer">Question Weight is incorrect.</span>
            <span *ngIf="questionWeight.errors?.max">Question Weight is incorrect.</span>
          </p>
        </div>
      </div>

      <manager-quiz-question-answers [question]="questionDraft.question" [answersReadonly]="!isQuestionEditable"
                                     *ngIf="isQuestionEditable" [messageEditMode]="'inline'"
                                     [formOptions]="editQuestionForm" [autoAdd]="true"></manager-quiz-question-answers>

    </div>
    <div *ngIf="!isQuestionEditable && !quiz.scoreTypeId">
      <span class="alert alert-warning">Question properties are readonly.</span>
    </div>
  </div>
  <div class="modal-footer">
    <fieldset class="no-margin-bottom">
      <button type="button" class="btn btn-warning" (click)="cancel()">Cancel</button>
      <button type="submit" class="btn btn-success"
              *ngIf="isQuestionEditable || quiz.scoreTypeId">Apply</button>
    </fieldset>
  </div>
</form>
