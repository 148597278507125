<div class="row" ngModelGroup>
  <div class="col-sm-12">
    <div class="input-group">
      <select class="form-control" id="questionType" [(ngModel)]="questionType" name="questionType">
        <option [value]="null" [hidden]="questionType">Choose Question type</option>
        <option *ngFor="let questionTypeValue of questionTypes" [ngValue]="questionTypeValue">{{ questionTypeValue.name }}</option>
      </select>
      <span class="input-group-btn">
          <button class="btn btn-default" type="button" [disabled]="!questionType"
                  title="Create a new question of the selected type"
                  (click)="setQuestionType()">Create Question
          </button>
        </span>
    </div>
  </div>
</div>
