import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { IQuestion } from 'modules/quiz/models/quiz.model';
import { Observable } from 'rxjs';
import { QuizQuestionService } from 'modules/quiz/services/quiz-question.service';
import { questionTypeExtension, QuizQuestionType } from 'modules/quiz';
import { GlobalConfig } from 'core/environment';
import {
  QuizQuestionsCategoryService
} from 'modules/admin/quiz/question/category/services/quiz-questions-category.service';
import { QuizOptionsService } from 'modules/quiz/services/quiz-options.service';
import { IQuestionTypeExtension } from 'modules/admin/quiz/models/quiz.model';
import { IQuizQuestionCategory } from 'modules/admin/quiz/question/category/models/category.model';
import { ControlContainer, NgForm } from '@angular/forms';
import { IQuizQuestionEvent } from 'modules/admin/quiz/question';
import { IFileUploadOptions } from 'components/file-upload/file-upload.model';
import { IUploadedTmpFile } from 'components/file-upload/single-file-upload.upgraded';
import _ from 'lodash';
import { QuizTemporaryFilesService } from 'modules/quiz/services/temporary-files.service';


class UnsubscriptionGuard {
  protected subscriptions = [];

  destroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  set subscription(subscription) {
    this.subscriptions.push(subscription);
  }

  unsubscribe(subscription) {
    this.subscriptions = _.without(this.subscriptions, subscription);
    subscription.unsubscribe();
  }
}

@Component({
  selector: 'edit-quiz-question',
  templateUrl: './edit-question.component.html',
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class EditQuestionComponent implements OnInit, OnDestroy {
  static readonly selector = 'editQuizQuestion';
  @Input() question: IQuestion;
  @Input() useCategories: boolean;
  @Input() formOptions: NgForm;
  @Input() hideActivity?: boolean;
  @Input() hideRandomOrder?: boolean;
  @Input() showColumns?: boolean;
  @Input() scoreTypeId?: number;
  @Input() maxlength?: number;
  @Input() emitter?: Observable<IQuizQuestionEvent>;

  katexMessageVisible = this.globalConfig.settings.katexMessageVisible;
  scorable: boolean;
  QuizQuestionType = QuizQuestionType;
  questionTypeExtension = questionTypeExtension;
  questionImage: IUploadedTmpFile;
  questionTypes: IQuestionTypeExtension[] = [];
  categories: IQuizQuestionCategory[] = [];
  fileUploadOptions: IFileUploadOptions;
  useFlatSelect = !this.globalConfig.settings.quizEdit?.questions?.simpleSelect;
  private guard = new UnsubscriptionGuard();

  constructor(
    private quizQuestionService: QuizQuestionService,
    private quizOptionsService: QuizOptionsService,
    private quizQuestionCategory: QuizQuestionsCategoryService,
    private globalConfig: GlobalConfig,
    private quizTemporaryFilesService: QuizTemporaryFilesService,
  ) { }

  ngOnInit() {
    if (this.emitter) {
      this.emitter.subscribe((data) => {
        this.processTmpImage(data.question);

        if (data.event === 'event:quizQuestion:beforeSave') {
          data.question.categoryId = data.question.category ? data.question.category.id : null;
          data.question.category = null;
        }
      });
    }

    this.scorable = this.quizOptionsService.isQuizTypeScorable(this.question.targetQuizTypeId);
    this.guard.subscription = this.quizOptionsService
      .getQuizQuestionTypes(this.scorable, this.question.targetQuizTypeId)
      .subscribe((questionTypes) => {
        this.questionTypes = questionTypes;
      });

    if (this.useCategories) {
      this.guard.subscription = this.quizQuestionCategory.query({
        active: true
      }).subscribe((response) => {
        this.categories = response.items;
      });
    }

    this.questionImage = this.quizTemporaryFilesService.getStoredFile(this.question.tmpImageFile?.tmpName);

    this.fileUploadOptions = {
      endPoint: '/a/quizzes/media/' + this.question.globalId + '/temp_image/?fullsize',
      acceptFileTypes: 'image/gif,image/jpeg,image/png,image/bmp',
      maxFileSizeMb: 12,
    };
  }

  ngOnDestroy() {
    this.guard.destroy();
  }

  processTmpImage(question: IQuestion) {
    if (this.questionImage) {
      this.quizTemporaryFilesService.storeFile(this.questionImage);
      question.tmpImageFile = { tmpName: this.questionImage.name };
    } else {
      question.tmpImageFile = null;
    }
  }
}
