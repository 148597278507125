import { Injectable } from '@angular/core';
import { ISearchItem, SearchService } from 'modules/search/services';


export interface IQuizQuestionSearchItem extends ISearchItem{
  categoryName: string;
  selected: boolean;
  added: boolean;
}

@Injectable()
export class QuizQuestionsSearchService extends SearchService<IQuizQuestionSearchItem> {
  static readonly endpoint = '/a/admin/search/';
  type = 'quiz_question';
  sequentRequestItemsCount = 10;
  protected get endpoint(): string {
    return QuizQuestionsSearchService.endpoint;
  }
}
