import { Component, Input } from '@angular/core';
import { IQuestion } from 'modules/quiz/models/quiz.model';
import { QuizOptionsService } from 'modules/quiz/services/quiz-options.service';
import { IQuestionTypeExtension } from 'modules/admin/quiz/models/quiz.model';
import { ControlContainer, NgForm } from '@angular/forms';


@Component({
  selector: 'select-question-type',
  templateUrl: './select-question-type.component.html',
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class SelectQuestionTypeComponent {
  @Input() question: IQuestion;
  @Input() questionTypes: IQuestionTypeExtension[];
  @Input() quizTypeId: number;
  @Input() scoreTypeId?: number;
  questionType: IQuestionTypeExtension = null;

  constructor(
    private quizOptionsService: QuizOptionsService,
  ) { }

  setQuestionType() {
    this.quizOptionsService
      .isQuestionOptional(this.quizTypeId, this.questionType.typeId || this.questionType.id)
      .subscribe((optional) => {
        Object.assign(this.question, {
          typeId: this.questionType.typeId || this.questionType.id,
          typeName: this.questionType.name,
          typeExtensionId: this.questionType.typeExtensionId,
          scorable: this.questionType.scorable,
          skipQuestionAvailable: optional,
          answerVariants: Object.assign([], this.questionType.answerVariants || [])
        });
        this.questionType = null;
      });
  }
}
