import { AfterViewInit, Component, OnDestroy } from '@angular/core';
import { IQuiz } from 'modules/quiz/models/quiz.model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Unsubscribable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { QuizService } from 'modules/quiz/services/quiz.service';


@Component({
  selector: 'set-introductory-text-dialog',
  templateUrl: './set-introductory-text-dialog.component.html',
})
export class SetIntroductoryTextDialogComponent implements AfterViewInit, OnDestroy {
  quiz: IQuiz;
  introductoryText:string;
  private subscriber?: Unsubscribable;

  constructor(
    private activeModal: NgbActiveModal,
    private quizService: QuizService,
  ) {  }

  get saving() {
    return !!this.subscriber;
  }

  ngAfterViewInit() {
    this.introductoryText = this.quiz.introductoryText;
  }

  ngOnDestroy() {
    this.subscriber?.unsubscribe();
  }

  onContentChanged(content: string) {
    this.introductoryText = content;
  }

  saveIntroductoryText() {
    this.subscriber = this.quizService.update(this.quiz.id,
      {
        introductoryText: this.introductoryText ?? ''
      })
      .pipe(
        finalize(() => {
          this.activeModal.close();
          this.subscriber.unsubscribe();
          delete this.subscriber;
        }),
      )
      .subscribe((quiz) => {
        Object.assign(this.quiz, quiz);
      });
  }

  cancel() {
    this.activeModal.dismiss();
  }
}
