import { Component, Input } from '@angular/core';
import { IQuestion } from 'modules/quiz/models/quiz.model';
import { QuizOptionsService } from 'modules/quiz/services/quiz-options.service';
import { IQuestionTypeExtension } from 'modules/admin/quiz/models/quiz.model';
import { QuizQuestionsManagerService } from 'modules/admin/quiz/question/services/quiz-questions-manager.service';
import { ControlContainer, NgForm } from '@angular/forms';


@Component({
  selector: 'select-question-type-flat',
  templateUrl: './select-question-type-flat.component.html',
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class SelectQuestionTypeFlatComponent {
  @Input() question: IQuestion;
  @Input() questionTypes: IQuestionTypeExtension[];
  @Input() quizTypeId: number;
  @Input() scoreTypeId?: number;
  createQuestionMode = 1;
  selectedQuestion: IQuestion;
  questionType: IQuestionTypeExtension;

  constructor(
    private quizOptionsService: QuizOptionsService,
    private quizQuestionsManagerService: QuizQuestionsManagerService,
  ) { }

  setQuestionType() {
    if (this.createQuestionMode === 1) {
      this.quizOptionsService
        .isQuestionOptional(this.quizTypeId, this.questionType.typeId || this.questionType.id)
        .subscribe((optional) => {
          Object.assign(this.question, {
            typeId: this.questionType.typeId || this.questionType.id,
            typeName: this.questionType.name,
            typeExtensionId: this.questionType.typeExtensionId,
            scorable: this.questionType.scorable,
            skipQuestionAvailable: optional,
            answerVariants: Object.assign([], this.questionType.answerVariants || [])
          });
          this.questionType = null;
        });
    } else {
      if (this.selectedQuestion) {
        Object.assign(this.question,
          this.quizQuestionsManagerService
            .prepareQuestionCopy(this.selectedQuestion, null, this.question.quizId, this.question.globalId)
        );
        this.selectedQuestion = null;
      }
    }
  }

  addQuestion(question: IQuestion) {
    this.selectedQuestion = question;
  }

  clearSelected() {
    this.selectedQuestion = null;
  }
}
