import { Component, EventEmitter, Injector, Input, OnInit, Output } from '@angular/core';
import { IAnswerVariant, IQuestion } from 'modules/quiz/models/quiz.model';
import { QuizOptionsService } from 'modules/quiz/services/quiz-options.service';
import { QuizQuestionsManagerService } from 'modules/admin/quiz/question/services/quiz-questions-manager.service';
import { ControlContainer, NgForm } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { questionTypeExtension, QuizQuestionType } from 'modules/quiz';
import _ from 'lodash';
import { moveItemInArray } from '@angular/cdk/drag-drop';
import { QuestionIntegrityRulesService } from 'modules/admin/quiz/question/services/question-intergity-rules.service';
import { QuizTemporaryFilesService } from 'modules/quiz/services/temporary-files.service';
import {
  SetAnswerFeedbackMessageComponent
} from 'modules/admin/quiz/question/components/answers/modal/set-answer-feedback-message.component';


@Component({
  selector: 'manager-quiz-question-answers',
  templateUrl: './manage-question-answers.component.html',
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class ManagerQuizQuestionAnswersComponent implements OnInit {
  static readonly selector = 'managerQuizQuestionAnswers';
  @Input() formOptions: NgForm;
  @Input() autoAdd: boolean;
  @Input() messageEditMode?: 'inside' | 'outside' | 'hide' | 'inline';
  @Input() answersReadonly: boolean;
  @Input() showRandomOrder: boolean;
  @Input() showOther: boolean;
  @Output() editMode = new EventEmitter<boolean>();

  questionValidationObject: IQuestion;
  answerVariantToEdit?: IAnswerVariant = null;
  scorrable = false;
  questionAnswerTypes = QuizQuestionType;
  questionTypeExtension = questionTypeExtension;
  questionRestrictions = this.questionIntegrityRules.restrictions;
  tmpFiles = this.quizTemporaryFilesService.getFiles();
  answerVariant: IAnswerVariant;

  private _question: IQuestion;

  constructor(
    private quizOptionsService: QuizOptionsService,
    private quizQuestionsManagerService: QuizQuestionsManagerService,
    private ngbModal: NgbModal,
    private questionIntegrityRules: QuestionIntegrityRulesService,
    private quizTemporaryFilesService: QuizTemporaryFilesService,
    private injector: Injector
  ) { }

  @Input() set question(question: IQuestion) {
    this._question = question;
    this.questionValidationObject = Object.assign({}, this._question);
  }

  get question(): IQuestion {
    return this._question;
  }

  ngOnInit() {
    this.messageEditMode = this.messageEditMode || 'outside';
    this.scorrable = this.quizOptionsService.isQuizTypeScorable(this.question.targetQuizTypeId);
    const restrictionIndex = this.question.typeId * 100 + (this.question.typeExtensionId || 0);

    this.questionValidationObject = Object.assign({}, this.question);

    if (this.autoAdd && !this.question.answerVariants.length &&
      this.questionRestrictions[restrictionIndex]?.maxVariants === null) {
      this.editAnswerVariant();
    }
  }

  editAnswerVariant(answerVariant?: IAnswerVariant) {
    this.answerVariantToEdit = answerVariant || {};
    this.editMode.emit(true);
  }

  saveAnswerVariant(answerVariant: IAnswerVariant) {
    Object.assign(this.answerVariantToEdit, answerVariant);

    //Add new answer variant to the question
    if (!this.question.answerVariants.find(item => this.answerVariantToEdit === item)) {
      this.question.answerVariants.push(this.answerVariantToEdit as IAnswerVariant);
    }

    this.questionIntegrityRules.adjustAnswerVariantsCorrectness(this.question, this.answerVariantToEdit);

    this.cancelEdit();
    this.questionValidationObject = Object.assign({}, this.question);
  }

  isEditMode() {
    return !!this.answerVariantToEdit || this.answersReadonly;
  }

  isObjectEmpty(object) {
    return _.isEqual({}, object);
  }

  cancelEdit() {
    this.answerVariantToEdit = null;
    this.editMode.emit(false);
  }

  deleteAnswerVariant(item: IAnswerVariant) {
    this.question.answerVariants = this.question.answerVariants.filter(answer => answer !== item);
    this.questionValidationObject = Object.assign({}, this.question);
  }

  setAnswerFeedback() {
    const modalRef = this.ngbModal.open(SetAnswerFeedbackMessageComponent, { injector: this.injector });

    modalRef.componentInstance.question = Object.assign({}, this.question);

    return modalRef.result.then((result: IQuestion) => {
      Object.assign(this.question, result);
    }, () => null);
  }

  dropCallback(event: any) {
    if (event.currentIndex === event.previousIndex) {
      return;
    }

    moveItemInArray(this.question.answerVariants, event.previousIndex, event.currentIndex);
  }
}
