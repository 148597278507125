<div class="col-xs-12" name="assignQuestionCategoryForm" id="assignQuestionCategoryForm"
     #assignQuestionCategoryForm="ngModelGroup" ngModelGroup>

  <div class="row">
    <div class="form-group"
         [ngClass]="{'has-error':categorySelection.invalid && (formOptions.submitted || categorySelection.dirty)}">
      <label class="col-sm-3 control-label text-left" for="categorySelection">Category</label>

      <div class="col-sm-6 sm-margin-bottom">
        <select class="form-control required" [ngModel]="parentCategoryId" id="categorySelection"
                [disabled]="!categories.length" name="categorySelection" (ngModelChange)="parentCategoryIdChanged($event)"
                #categorySelection="ngModel" required>
          <option [value]="null">Select Category</option>
          <option *ngFor="let category of categories" [ngValue]="category.id">{{ category.name }}</option>
        </select>

        <p class="help-block"
           *ngIf="categorySelection.invalid && (formOptions.submitted || categorySelection.dirty)">
          <span *ngIf="categorySelection.errors?.required">Category is Required</span>
        </p>
      </div>
    </div>

    <div class="form-group no-margin-bottom"
         [ngClass]="{'has-error':subCategorySelection.invalid && (formOptions.submitted || subCategorySelection.dirty)}">
      <label class="col-sm-3 control-label text-left" for="subCategorySelection">Sub-Category</label>

      <div class="col-sm-6 no-margin-bottom">
        <select class="form-control required" [(ngModel)]="categoryId" id="subCategorySelection"
                (ngModelChange)="categoryIdChanged($event)"
                [disabled]="!subCategories.length" name="subCategorySelection"
                #subCategorySelection="ngModel" required>
          <option [value]="null">Select Sub-Category</option>
          <option *ngFor="let category of subCategories" [ngValue]="category.id">{{ category.name }}</option>
        </select>

        <p class="help-block"
           *ngIf="subCategorySelection.invalid && (formOptions.submitted || subCategorySelection.dirty)">
          <span *ngIf="subCategorySelection.errors?.required">Sub-Category is Required</span>
        </p>
      </div>
    </div>
  </div>
</div>
