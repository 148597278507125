<div class="modal-header">
  <button type="button" class="close" aria-label="Close" (click)="closeModal()">
    <span aria-hidden="true">&times;</span>
  </button>
  <h4 class="modal-title">Question Version</h4>
</div>

<div class="modal-body">
  <p>This question has been used in existing exams and can't be edited.</p>
</div>

<div class="modal-footer">
  <button type="button" class="btn btn-warning" (click)="closeModal()" autoFocus>Cancel</button>
  <button type="button"
          class="btn btn-primary" (click)="newVersion()">Create New Version</button>
</div>
