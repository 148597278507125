import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { NotificationService } from 'ajs/modules/app/environment/notification-service';
import { BackUrlService } from 'core/navigation/back-url.service.ajs-upgraded-provider';
import { catchError, EMPTY, finalize, from, tap, Unsubscribable } from 'rxjs';
import { IInstructor } from 'modules/instructors/models/instructor.model';
import { InstructorsService } from 'modules/instructors/services/instructor.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { DeleteInstructorComponent } from 'modules/admin/instructors/edit/modal/delete-instructor.component';
import { ICourse } from 'modules/course/common/models/course.model';


@Component({
  selector: 'edit-instructor',
  templateUrl: './edit-instructor.component.html',
})
export class EditInstructorComponent implements OnDestroy {
  @Input() instructor: IInstructor;
  @Input() course?: ICourse;
  @Output() created = new EventEmitter<IInstructor>();

  deleteError = '';
  saveSubscriber: Unsubscribable;

  private modalSubscriber?: Unsubscribable;

  constructor(
    private instructorsService: InstructorsService,
    private backUrlService: BackUrlService,
    private notificationService: NotificationService,
    private ngbModalService: NgbModal,
  ) { }

  ngOnDestroy(): void {
    if (this.saveSubscriber) {
      this.saveSubscriber.unsubscribe();
      delete this.saveSubscriber;
    }
  }

  redirectBack(): void {
    this.backUrlService.goBack(false);
  }

  submit(form: FormGroup): void {
    if (form.valid) {
      const query = Object.assign({}, this.instructor);

      this.saveSubscriber = (this.instructor.id
        ? this.instructorsService.update(this.instructor.id, query)
        : this.instructorsService.post(query))
        .pipe(
          tap((instructor) => {
            if (!this.instructor.id) {
              this.created.emit(instructor);
            }
          }),
          tap((instructor) => {
            Object.assign(this.instructor, instructor);
            this.notificationService.info('Your changes have been saved.', 3e3);
          }),
          finalize(() => {
            this.saveSubscriber.unsubscribe();
            delete this.saveSubscriber;
            form.markAsPristine();
          })
        ).subscribe();
    } else {
      this.notificationService.error('Please fill in all required fields', 2e3);
    }
  }

  removeInstructor() {
    const modalReference: NgbModalRef = this.ngbModalService.open(DeleteInstructorComponent, {
      backdrop: 'static',
      animation: true,
    });

    (<DeleteInstructorComponent>modalReference.componentInstance).instructor = this.instructor;

    this.modalSubscriber = from(modalReference.result).pipe(
      tap(() => {
        this.redirectBack();
      }),
      catchError(() => EMPTY),
      finalize(() => {
        this.clearModalSubscriber();
      })
    ).subscribe();
  }

  private clearModalSubscriber(): void {
    this.modalSubscriber?.unsubscribe();
    delete this.modalSubscriber;
  }
}
