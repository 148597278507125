import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IQuestion } from 'modules/quiz/models/quiz.model';
import { IQuestionTypeExtension } from 'modules/admin/quiz/models/quiz.model';
import { QuizQuestionService } from 'modules/quiz/services/quiz-question.service';
import { GlobalConfig } from 'core/environment';
import { Unsubscribable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import {
  IQuizQuestionSearchItem,
  QuizQuestionsSearchService
} from 'modules/admin/quiz/services/quiz-questions-search.service';


export interface IQuizQuestionFilter {
  active: boolean;
  questionTypes?: boolean;
}

@Component({
  selector: 'question-selection',
  templateUrl: './question-selection.component.html',
})
export class QuestionSelectionComponent implements OnInit {
  static readonly selector = 'questionSelection';
  @Input() quizTypeId: number;
  @Input() selectedQuestions: IQuestion[] = [];
  @Input() questionTypes: IQuestionTypeExtension[];
  @Input() filters: IQuizQuestionFilter;
  @Input() visibilityType: any;
  @Output() addHandler = new EventEmitter<IQuestion>();

  query = '';
  showInactive = false;
  currentPage = 1;
  topicId = null;
  questionType: IQuestionTypeExtension = null;
  items: IQuizQuestionSearchItem[] = [];
  availableQuestionTypes = null;
  availableQuestionTypeExtensions = null;
  searchRequestPromise?: Unsubscribable;
  selectedQuestionsList: { [key: number]: boolean } = {};
  itemCount: number;
  readonly itemsPerPage = 10;

  constructor(
    private searchService: QuizQuestionsSearchService,
    private quizQuestionService: QuizQuestionService,
    private globalConfig: GlobalConfig,
  ) { }

  ngOnInit() {
    const settings = this.globalConfig.settings.quizEdit;

    this.availableQuestionTypes = settings.availableQuestionTypes[this.quizTypeId];
    this.availableQuestionTypeExtensions = settings.availableQuestionTypeExtensions[this.quizTypeId];

    this.selectedQuestionsList = this.selectedQuestions?.reduce((result, item) => {
      result[item.id] = true;

      return result;
    }, {});


    this.applySearch();
  }

  pageChanged(page) {
    if (page === this.currentPage) {
      return;
    }

    this.currentPage = page;
    this.search();
  }

  applySearch() {
    this.currentPage = 1;
    this.search();
  }

  categoryChanged(topicId) {
    this.topicId = topicId;

    this.applySearch();
  }

  addQuestion(question: IQuizQuestionSearchItem) {
    if (!question.selected && !this.searchRequestPromise) {
      this.searchRequestPromise = this.quizQuestionService.get(question.id)
        .pipe(finalize(() => {
          this.searchRequestPromise.unsubscribe();
          delete this.searchRequestPromise;
        }))
        .subscribe((response) => {
          this.addHandler.emit(response);
        });
    }
  }

  private buildQuestionTypesForSearch(types, extensionTypes) {
    const resultTypes = [];

    if (types) {
      types.forEach(type => {
        resultTypes.push(type * 100);
      });
    }

    if (extensionTypes) {
      extensionTypes.forEach(extensionType => {
        resultTypes.push(extensionType.typeId * 100 + extensionType.id);
      });
    }

    return resultTypes;
  }

  private initSearchQuery() {
    let searchQuery = {
      page: this.currentPage,
      target_quiz_type_id: this.quizTypeId,
      sort: this.query ? 'relevance' : 'asc',
      startDoc: (this.currentPage - 1) * this.itemsPerPage || 0,
      count: this.itemsPerPage
    };

    if (this.visibilityType) {
      searchQuery = Object.assign(searchQuery, { visibility_type: this.visibilityType });
    }

    let typeQuery = {};

    if (this.questionTypes && this.questionType) {
      typeQuery = {
        question_type_id: [(this.questionType.typeExtensionId ? this.questionType.typeId : this.questionType.id) * 100 +
        (this.questionType.typeExtensionId || 0)]
      };
    } else {
      typeQuery = {
        question_type_id: this.buildQuestionTypesForSearch(this.availableQuestionTypes,
          this.availableQuestionTypeExtensions)
      };
    }

    searchQuery = Object.assign(typeQuery, searchQuery);

    if (!this.showInactive) {
      searchQuery = Object.assign({ active: true }, searchQuery);
    }

    if (this.query) {
      searchQuery = Object.assign({ query: this.query }, searchQuery);
    }

    if (this.topicId) {
      searchQuery = Object.assign({ topic_id: this.topicId }, searchQuery);
    }

    if (!this.globalConfig.settings.quizEdit?.questionBank?.cloneQuestions) {
      searchQuery = Object.assign({ no_owner: true }, searchQuery);
    }

    return searchQuery;
  }

  private search() {
    const searchQuery = this.initSearchQuery();

    this.items = [];
    this.searchRequestPromise = this.searchService.search(searchQuery)
      .pipe(finalize(() => {
        this.searchRequestPromise.unsubscribe();
        delete this.searchRequestPromise;
      }))
      .subscribe((response) => {
        this.items = response.items;
        this.items.forEach(item => {
          item.selected = !!this.selectedQuestionsList[item.id];
        });
        this.itemCount = response.count;
      });
  }
}
