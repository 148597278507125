import { Component, Input, OnInit } from '@angular/core';
import { IQuestion } from 'modules/quiz/models/quiz.model';
import { ControlContainer, NgForm } from '@angular/forms';
import {
  QuizQuestionsCategoryService
} from 'modules/admin/quiz/question/category/services/quiz-questions-category.service';
import { IQuizQuestionCategory } from 'modules/admin/quiz/question/category/models/category.model';


@Component({
  selector: 'assign-quiz-question-category',
  templateUrl: './assign-question-category.component.html',
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class AssignQuizQuestionCategoryComponent implements OnInit {
  @Input() question: IQuestion;
  @Input() formOptions: NgForm;

  categoryId: number;
  subCategories: IQuizQuestionCategory[] = [];
  categories: IQuizQuestionCategory[] = [];
  parentCategoryId: number;
  constructor(
    private quizQuestionsCategoryService: QuizQuestionsCategoryService
  ) { }

  ngOnInit() {
    this.parentCategoryId = this.question.category?.parentId ?? null;
    this.categoryId = this.question.category?.id ?? null;

    this.quizQuestionsCategoryService.query({  })
      .subscribe((response) => {
        this.categories = response.items.filter(cat => cat.active || cat.id === this.question.category?.parentId);
      });

    if (this.parentCategoryId) {
      this.loadSubCategories(this.parentCategoryId);
    }
  }

  categoryIdChanged(categoryId: number) {
    if (categoryId && this.subCategories?.length) {
      this.question.category = this.subCategories.find(cat => cat.id === categoryId);
    }
  }

  parentCategoryIdChanged(parentId) {
    if (parentId) {
      this.question.category = null;
      this.categoryId = null;
      this.subCategories = [];
      this.loadSubCategories(parentId);
    }
  }

  loadSubCategories(parentId:number) {
    this.quizQuestionsCategoryService.query({ parent_id: parentId })
      .subscribe((response) => {
        this.subCategories = response.items.filter(subCat => subCat.active || subCat.id === this.categoryId);
      });
  }
}
