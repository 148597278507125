

export enum AchievementType {
  COURSE = 9,
  COURSES = 10,
  HOURS_AMOUNT =7,
  COURSES_AMOUNT = 8,
}

export interface IBadgeFile {
  id: number|string;
  rnd?: number;
}

export interface IBadge {
  id: number|'custom';
  file: IBadgeFile;
  displayOrder: number;
}

export interface IAchievementType {
  id: number;
  name: string;
  achievementDeliveryTypeId: number;
  achievementDeliveryType: string;
  badges: IBadge[];
}

export interface IAchievementTypes{
  items: IAchievementType[]
}


export interface IAchievementTypeRequestOptions{
  deliveryTypeId: number;
}
