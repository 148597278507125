import { Injectable, Injector } from '@angular/core';
import { catchError, EMPTY, from, map, Observable, of, switchMap } from 'rxjs';
import { quizEnums, QuizType } from 'modules/quiz';
import _ from 'lodash';
import { QuizOptionsService } from 'modules/quiz/services/quiz-options.service';
import { StateService } from '@uirouter/core';
import { StateDeclaration } from '@uirouter/core/lib/state/interface';
import { CurrentUserService } from 'ajs/modules/app/current-user.service';
import { IQuiz } from 'modules/quiz/models/quiz.model';
import { NotificationService } from 'ajs/modules/app/environment/notification-service';
import { QuizService } from 'modules/quiz/services/quiz.service';
import { tap } from 'rxjs/operators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PreviewQuizComponent } from 'modules/admin/quiz/componenets/modal/preview-quiz.component';
import { DeleteQuizComponent } from 'modules/admin/quiz/componenets/modal/delete-quiz.component';
import { CloneQuizComponent } from 'modules/admin/quiz/componenets/modal/clone-quiz.component';
import { ICourseQuizType, ICourseQuizTypeCategory } from 'modules/course/quiz/models/course-quiz.models';
import {
  SetIntroductoryTextDialogComponent
} from 'modules/admin/quiz/edit/componenets/modal/set-introductory-text-dialog.component';
import { SetFinalTextDialogComponent } from 'modules/admin/quiz/edit/componenets/modal/set-final-text-dialog.component';


@Injectable()
export class QuizManagerService {
  constructor(
    private quizOptionsService: QuizOptionsService,
    private stateService: StateService,
    private currentUser: CurrentUserService,
    private notificationService: NotificationService,
    private quizService: QuizService,
    private ngbModal: NgbModal,
  ) { }

  getTypeCategory(categoryId: QuizType): Observable<ICourseQuizTypeCategory> {
    return this.quizOptionsService.getOptions()
      .pipe(
        map(options => _.find(options.courseQuizTypeCategories, { id: categoryId }))
      );
  }

  showDeleteQuizDialog(quiz: IQuiz, quizTypeName: string, reload = false): Observable<boolean | null> {
    const modalRef = this.ngbModal.open(DeleteQuizComponent);

    modalRef.componentInstance.quiz = quiz;
    modalRef.componentInstance.reload = reload;
    modalRef.componentInstance.quizTypeName = quizTypeName;

    return from(modalRef.result)
      .pipe(catchError(() => EMPTY));
  }

  getQuizEditState(quiz: IQuiz): StateDeclaration | undefined {
    return _.find(this.stateService.get(), function (state) {
      return state.data && state.data.quizTypeId === quiz.quizTypeId && _.startsWith(state.name, 'edit.quiz.');
    });
  }

  previewQuiz(quiz: IQuiz, quizTypeName: string, reload = false): void {
    const modalRef = this.ngbModal.open(PreviewQuizComponent, { size: 'lg' });

    modalRef.componentInstance.quiz = quiz;
    modalRef.componentInstance.reload = reload;
    modalRef.componentInstance.quizTypeName = quizTypeName;
  }

  cloneQuiz(quiz: IQuiz, quizTypeName: string, reload = false) {
    const modalRef = this.ngbModal.open(CloneQuizComponent);

    modalRef.componentInstance.quiz = quiz;
    modalRef.componentInstance.reload = reload;
    modalRef.componentInstance.quizTypeName = quizTypeName;
  }

  updateQuiz(quiz: IQuiz, message: string) {
    throw new Error(['Method not implemented.', quiz, message].join());
  }

  getQuizType(quizTypeId: QuizType): Observable<ICourseQuizType> {
    return this.quizOptionsService.getOptions()
      .pipe(map((options) => {
        return _.find(options.courseQuizTypes, {
          system: false,
          id: quizTypeId
        });
      }));
  }

  getQuizTypeCategory(quizTypeId: QuizType): Observable<ICourseQuizTypeCategory> {
    return this.quizOptionsService.getOptions()
      .pipe(map((options) => {
        const type = _.find(options.courseQuizTypes, {
          system: false,
          id: quizTypeId
        });

        return _.find(options.courseQuizTypeCategories, { id: type.categoryId });
      }));
  }

  showFinalTextDialog(quiz: IQuiz, injector:Injector) {
    const modalRef = this.ngbModal.open(SetFinalTextDialogComponent,     {
      injector: injector,
      backdrop: 'static',
    });

    modalRef.componentInstance.quiz = quiz;
  }

  showIntroductoryTextDialog(quiz: IQuiz, injector:Injector) {
    const modalRef = this.ngbModal.open(SetIntroductoryTextDialogComponent,
      {
        injector: injector,
        backdrop: 'static',
      });

    modalRef.componentInstance.quiz = quiz;
  }

  setActiveFlag(quiz: IQuiz, active: boolean): Observable<boolean> {
    return this.getTypeCategory(quiz.quizTypeId)
      .pipe(
        switchMap((quizTypeCategory) => {
          if (active && !quiz.questions.length) {
            const message = 'The ' + quizTypeCategory.name + ' has no questions defined.';

            this.notificationService.error(message, 5e3);

            return of(false);
          }

          return this.quizService.update(quiz.id, { active })
            .pipe(
              tap(() => {
                this.notificationService.info(active ? 'The ' + quizTypeCategory.name + ' has been activated.' :
                  'The ' + quizTypeCategory.name + ' has been deactivated.', 3e3);
              }),
              tap((updatedQuiz) => {
                Object.assign(quiz, updatedQuiz);
              }),
              switchMap(() => of(true))
            );
        }));
  }

  isQuizEditable(quiz: IQuiz): boolean {
    const user = this.currentUser.get();

    return _.intersection(quiz.permissions, ['quiz.edit']).length > 0 || _.get(quiz, 'createdBy.id') === user.id;
  }

  isContentQuizType(typeId:QuizType): boolean {
    return _.includes(quizEnums.contentQuizTypes, typeId);
  }
}
