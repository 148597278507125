<div class="modal-header">
  <button type="button" class="close" aria-label="Close" (click)="closeModal()">
     <span aria-hidden="true">&times;</span>
  </button>
  <h4 class="modal-title">Delete</h4>
</div>

<div class="modal-body">
  <p *ngIf="!reloading">
    <span *ngIf="!quiz.hasResults && !quiz.hasUsages">Delete the '{{quiz.title}}'?</span>

    <span *ngIf="quiz.hasResults">
      The {{quizTypeName|lowercase}} can not be deleted because it has user answers.
    </span>
    <span *ngIf="!quiz.hasResults && quiz.hasUsages">
      The {{quizTypeName|lowercase}} can't be deleted because it's assigned to courses.
    </span>
  </p>

  <div *ngIf="reloading && !deleteError">
    <div class="load-spinner"></div>
  </div>
  <div class="alert alert-sm alert-danger" *ngIf="deleteError">{{deleteError}}</div>
</div>

<div class="modal-footer">
  <fieldset [disabled]="deletionProgress" class="no-margin-bottom">
    <div *ngIf="!reloading && !quiz.hasResults && !quiz.hasUsages">
      <button type="button" class="btn btn-warning" (click)="closeModal()">Cancel</button>
      <button type="button" [ladda]="deletionProgress" data-spinner-color="#FFF"
              class="btn btn-primary" (click)="deleteQuiz()">Delete</button>
    </div>
    <div *ngIf="reloading || quiz.hasResults || quiz.hasUsages">
      <button type="button" class="btn btn-warning" (click)="closeModal()">OK</button>
    </div>
  </fieldset>
</div>
