<div class="modal-header">
  <button type="button" class="close" aria-label="Close" (click)="close()">
    <span aria-hidden="true">&times;</span>
  </button>
  <h4 class="modal-title">Answer Feedback</h4>
</div>

<div class="modal-body">
  <fieldset name="correctFeedback">
    <legend><label [for]="'correctFeedback'" class="font-weight-normal">Correct Feedback</label></legend>
    <!--Correct Answer Feedback-->
    <div class="form-group row">
      <div class="col-xs-12">
        <markdown-editor-component [(ngModel)]="question.correctFeedback" name="correctFeedback"
                                   [rows]="5"
                                   [id]="'correctFeedback'"></markdown-editor-component>
      </div>
    </div>
  </fieldset>

  <fieldset class="sm-margin-top" name="incorrectFeedback">
    <legend><label [for]="'incorrectFeedback'" class="font-weight-normal">Incorrect Feedback</label></legend>
    <div class="form-group row">
      <div class="col-xs-12">
        <markdown-editor-component [(ngModel)]="question.incorrectFeedback" name="incorrectFeedback"
                                   [rows]="5"
                                   [id]="'incorrectFeedback'"></markdown-editor-component>
      </div>
    </div>
  </fieldset>
</div>

<div class="modal-footer">
  <button type="button" class="btn btn-warning" (click)="close()" autoFocus>Cancel</button>
  <button type="button" class="btn btn-primary" (click)="applyChanges()">Apply</button>
</div>
