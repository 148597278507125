<div class="row" ngModelGroup>
  <div class="col-sm-12">
    <ul class="list-unstyled">
      <li class="radio sm-margin-bottom"> <!--ng-class="{'xs-margin-bottom': createQuestionMode === 2, 'no-margin-bottom':createQuestionMode === 1}"-->
        <label><input name="create_mode" [value]="1" [(ngModel)]="createQuestionMode" type="radio">Choose a question type</label>
        <div class="xs-margin-top margin-left-2x"  *ngIf="createQuestionMode === 1">
          <ul class="list-unstyled">
            <li *ngFor="let questionTypeIt of questionTypes" class="xs-margin-bottom">
              <label><input name="question_type" [value]="questionTypeIt" [(ngModel)]="questionType" type="radio" class="radio">{{questionTypeIt.name}}</label>
            </li>
          </ul>
        </div>
      </li>
      <li class="radio xs-margin-bottom" >
        <label><input name="create_mode" [value]="2" (click)="questionType=null;" [(ngModel)]="createQuestionMode" type="radio">Find and clone a question</label>
      </li>
    </ul>

    <div *ngIf="createQuestionMode === 2" class="sm-margin-top">

      <div *ngIf="selectedQuestion" class="panel panel-default panel-body use-default-cursor-all">
        <quiz-player-question [question]="selectedQuestion" [scoreTypeId]="scoreTypeId" *ngIf="selectedQuestion">
        </quiz-player-question>
      </div>

      <div *ngIf="!selectedQuestion">
        <question-selection [quizTypeId]="quizTypeId" [questionTypes]="questionTypes"
                                  (addHandler)="addQuestion($event)" [filters]="{'active': false, 'questionTypes': true}"
                                  *ngIf="!selectedQuestion"></question-selection>
      </div>
    </div>

    <div class="text-right">
      <button class="btn btn-default" type="button" [disabled]="!questionType && !selectedQuestion"
              title="Create a new question of the selected type"
              (click)="setQuestionType()">Next
      </button>
      <button class="btn btn-default" type="button" [disabled]="!selectedQuestion" *ngIf="selectedQuestion && createQuestionMode === 2"
              title="Create a new question of the selected type"
              (click)="clearSelected()">Find another question
      </button>
    </div>
  </div>
</div>
