import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { IQuizQuestion } from 'modules/quiz/models/quiz.model';
import {
  IQuizQuestionSearchItem,
  QuizQuestionsSearchService
} from 'modules/admin/quiz/services/quiz-questions-search.service';
import { finalize } from 'rxjs/operators';
import { IQuestionTypeExtension } from 'modules/admin/quiz/models/quiz.model';
import { Unsubscribable } from 'rxjs';
import { GlobalConfig } from 'core/environment';


@Component({
  selector: 'quiz-questions-selection-modal',
  templateUrl: './questions-selection-modal.component.html'
})
export class QuizQuestionsSelectionModalComponent implements OnInit {
  quizTypeId: number;
  selectedQuestions: IQuizQuestion[];

  topicId = null;
  questionType: IQuestionTypeExtension = null;
  visibilityType = 'inherited';
  availableQuestionTypes = null;

  items: IQuizQuestionSearchItem[] = [];
  itemCount: number;
  totalCheckedQuestions = 0;
  checkedQuestions: IQuizQuestionSearchItem[] = [];
  searchRequestPromise?: Unsubscribable;

  query = '';
  showInactive = false;
  currentPage = 1;
  readonly itemsPerPage = 10;

  constructor(
    private searchService: QuizQuestionsSearchService,
    private globalConfig: GlobalConfig,
    private activeModal: NgbActiveModal,
  ) { }

  ngOnInit() {
    this.applySearch();
  }

  cancel() {
    this.activeModal.dismiss('cancel');
  }

  pageChanged(page: number) {
    if (page === this.currentPage) {
      return;
    }

    this.currentPage = page;
    this.search();
  }

  applySearch(): void {
    this.currentPage = 1;
    this.search();
  }

  categoryChanged(topicId: number): void {
    this.topicId = topicId;

    this.applySearch();
  }

  toggleQuestion(question: IQuizQuestionSearchItem): void {
    if (question.added) {
      return;
    }

    if (question.id in this.checkedQuestions) {
      question.selected = false;
      delete this.checkedQuestions[question.id];
      this.totalCheckedQuestions--;
    } else {
      question.selected = true;
      this.checkedQuestions[question.id] = question;
      this.totalCheckedQuestions++;
    }
  }

  submit(): void {
    if (!Object.values(this.checkedQuestions).length) {
      this.cancel();
    } else {
      this.activeModal.close(this.checkedQuestions);
    }
  }


  private buildQuestionTypesForSearch(types, extensionTypes) {
    const resultTypes = [];

    if (types) {
      types.forEach(type => {
        resultTypes.push(type * 100);
      });
    }

    if (extensionTypes) {
      extensionTypes.forEach(extensionType => {
        resultTypes.push(extensionType.typeId * 100 + extensionType.id);
      });
    }

    return resultTypes;
  }

  private initSearchQuery() {
    const quizSettings = this.globalConfig.settings.quizEdit;

    let searchQuery = {
      page: this.currentPage,
      target_quiz_type_id: this.quizTypeId,
      sort: this.query ? 'relevance' : 'asc',
      startDoc: (this.currentPage - 1) * this.itemsPerPage || 0,
      count: this.itemsPerPage
    };

    if (this.visibilityType) {
      searchQuery = Object.assign(searchQuery, { visibility_type: this.visibilityType });
    }

    const typeQuery = {
      question_type_id: this.buildQuestionTypesForSearch(
        quizSettings.availableQuestionTypes[this.quizTypeId],
        quizSettings.availableQuestionTypeExtensions[this.quizTypeId]
      )
    };

    searchQuery = Object.assign(typeQuery, searchQuery);

    if (!this.showInactive) {
      searchQuery = Object.assign({ active: true }, searchQuery);
    }

    if (this.query) {
      searchQuery = Object.assign({ query: this.query }, searchQuery);
    }

    if (this.topicId) {
      searchQuery = Object.assign({ topic_id: this.topicId }, searchQuery);
    }

    if (!this.globalConfig.settings.quizEdit?.questionBank?.cloneQuestions) {
      searchQuery = Object.assign({ no_owner: true }, searchQuery);
    }

    return searchQuery;
  }

  private search() {
    const searchQuery = this.initSearchQuery();

    this.items = [];
    this.searchRequestPromise = this.searchService.search(searchQuery)
      .pipe(finalize(() => {
        this.searchRequestPromise.unsubscribe();
        delete this.searchRequestPromise;
      }))
      .subscribe((response) => {
        this.items = response.items;
        this.items.forEach(item => {
          item.added = item.selected = this.selectedQuestions.some(cq => item.id === cq.question.id.toString());
        });
        this.items.forEach(item => {
          item.selected = this.checkedQuestions.some(cq => item.id === cq.id);
        });
        this.itemCount = response.count;
      });
  }
}
