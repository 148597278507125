<div ngbDropdown>
  <span>
    <a href="#" title="Manage {{quizTypeName}}" class="dot-menu-large" ngbDropdownToggle>
      <span class="fa fa-ellipsis-v"></span>
    </a>
  </span>

  <div ngbDropdownMenu class="dropdown-menu-right">
    <a ngbDropdownItem uiSref="main.quiz" [uiParams]="{id: quiz.id}" title="Edit {{quizTypeName}}">Edit</a>
    <a href="#" ngbDropdownItem title="Preview {{quizTypeName}}" (click)="previewQuiz(quiz)">Preview questions</a>
    <a ngbDropdownItem uiSref="mainPrint.quizzes" [uiParams]="{id: quiz.id}" title="Print {{quizTypeName}}" target="_blank">Printable version</a>
    <div class="divider"></div>
    <a href="#" ngbDropdownItem title="Clone {{quizTypeName}}" (click)="cloneQuiz(quiz)">Clone</a>
    <div class="divider" *ngIf="canEdit"></div>
    <a href="#" ngbDropdownItem title="Deactivate {{quizTypeName}}" *ngIf="canEdit && quiz.active" (click)="setActiveFlag(quiz, false)">Deactivate</a>
    <a href="#" ngbDropdownItem title="Activate {{quizTypeName}}" *ngIf="canEdit && !quiz.active" (click)="setActiveFlag(quiz, true)">Activate</a>
    <a href="#" ngbDropdownItem title="Delete {{quizTypeName}}" *ngIf="canEdit" (click)="remove(quiz)">Delete</a>
  </div>
</div>
