import { EditAnswerComponent } from 'modules/admin/quiz/question/components/answers/edit-answer-dialog';
import { Component, Injector, Input } from '@angular/core';
import { QuestionIntegrityRulesService } from 'modules/admin/quiz/question/services/question-intergity-rules.service';
import { ControlContainer, NgForm } from '@angular/forms';


@Component({
  selector: 'edit-question-choice',
  templateUrl: './edit-question-choice.component.html',
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class EditQuestionChoiceComponent  extends  EditAnswerComponent {
    @Input() scorable: boolean;
    correctnessEditable: boolean;

    constructor(
      private questionIntegrityRules: QuestionIntegrityRulesService,
      injector: Injector,
    ) {
      super(injector);
    }

    override activate() {
      super.activate();

      this.correctnessEditable = this.questionIntegrityRules
        .checkAnswerVariantCorrectnessRelevance(this.question, this.answer || this.answerDraft);
    }
}
