import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'move-question-confirmation-dialog',
  templateUrl: './move-question-confirmation-dialog.component.html'
})
export class MoveQuestionConfirmationDialogComponent {
  constructor(
    private activeModal: NgbActiveModal,
  ) { }

  closeModal(): void {
    this.activeModal.dismiss();
  }
}
