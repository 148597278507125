import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { IQuestion } from 'modules/quiz/models/quiz.model';


@Component({
  selector: 'set-answer-feedback-message',
  templateUrl: './set-answer-feedback-message.component.html'
})
export class SetAnswerFeedbackMessageComponent {
  question: IQuestion;

  constructor(
    private activeModal: NgbActiveModal,
  ) { }

  applyChanges() {
    this.activeModal.close(this.question);
  }

  close(): void {
    this.activeModal.dismiss();
  }
}
