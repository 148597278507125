<div class="modal-header">
  <button type="button" class="close" aria-label="Close" (click)="closeModal()">
    <span aria-hidden="true">&times;</span>
  </button>
  <h4 class="modal-title">Question</h4>
</div>

<div class="modal-body">
  <div *ngIf="!question.hasUsages && !hasParent">
    <p>Delete question?</p>
    <div class="alert alert-sm alert-danger" *ngIf="deleteError">{{deleteError}}</div>
  </div>
  <div *ngIf="question.hasUsages || hasParent">
    <p>Cannot delete. Question has assignments or results.</p>
  </div>
</div>

<div class="modal-footer">
  <fieldset [disabled]="deletionProgress" class="no-margin-bottom" *ngIf="!question.hasUsages && !question.hasParent">
    <button type="button" class="btn btn-warning" (click)="closeModal()" autoFocus>Cancel</button>
    <button type="button" [ladda]="deletionProgress" data-spinner-color="#FFF"
            [disabled]="question.hasUsages"
            class="btn btn-primary" (click)="deleteQuestion()">Delete</button>
  </fieldset>

  <button type="button" *ngIf="question.hasUsages || question.hasParent"
          class="btn btn-warning" (click)="closeModal()">OK</button>
</div>

