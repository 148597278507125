import { Component, Input, OnInit } from '@angular/core';
import { HelpTextService } from 'modules/help-texts/services/help-text.service';
import { finalize, Unsubscribable } from 'rxjs';
import { IHelpText } from 'modules/help-texts/models/help-text.model';


@Component({
  selector: 'help-text-component',
  templateUrl: './help-text.component.html'
})
export class HelpTextComponent implements OnInit {
  @Input() siteSettingId?: number;
  loading = false;
  helpText?:IHelpText;
  private subscriber?:Unsubscribable;

  constructor(
    private helpTextService: HelpTextService,
  ) { }

  ngOnInit(): void {
    if (this.siteSettingId) {
      this.loading = true;

      this.subscriber = this.helpTextService
        .get({ siteSettingId: this.siteSettingId })
        .pipe(
          finalize(() => {
            this.loading = false;
          })
        )
        .subscribe(helpText => this.helpText = helpText);
    }
  }
}
