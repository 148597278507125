<div class="full-window top">
  <form #editInstructorForm="ngForm" id="editInstructorForm" name="editInstructorForm"
        class="form-horizontal"
        (ngSubmit)="submit(editInstructorForm.form)" accessible-form>
    <fieldset [disabled]="!!saveSubscriber">
      <!--  Top panel with control buttons -->
      <div class="navbar navbar-default navbar-fixed-top">
        <div class="container-fluid clearfix">
          <div class="row">
            <div class="col-sm-6 col-xs-6">
              <button type="button" class="btn btn-sm navbar-btn btn-warning"
                      (clickConfirmation)="redirectBack()"
                      clickConfirmationTitle="Resource"
                      clickConfirmationMessage="Discard unsaved changes?"
                      clickConfirmationButtonOk="Discard"
                      [clickConfirmationAllowed]="editInstructorForm.dirty">Cancel
              </button>
              <button type="button"
                      *ngIf="instructor.id"
                      class="btn btn-sm navbar-btn btn-danger"
                      (click)="removeInstructor()">Delete
              </button>
            </div>
            <div class="col-sm-6 col-xs-6">
              <button type="submit" class="pull-right btn btn-sm navbar-btn btn-success">Save</button>
            </div>
          </div>
        </div>
      </div>

      <!-- Content body -->
      <div class="content">
        <div class="row">
          <div class="col-xs-12">
            <fieldset>
              <legend>
                <span class="fa fa-wrench"></span>
                <span *ngIf="course">
                  {{course.name}} > Edit Session >
                </span>
                <span>{{instructor.id ? 'Edit' : 'Add'}} Instructor</span>
                <span class="pull-right required-text">(Fields marked below are required)</span>
              </legend>
              <div class="row">
                <div class="col-xs-12 col-sm-4">
                  <div class="form-group"
                       [ngClass]="{'has-error': firstName.invalid && (editInstructorForm.submitted || firstName.dirty)}">

                    <div class="col-xs-12">
                      <label for="firstName" class="">First Name <span class="sr-only">(Required)</span></label>
                    </div>

                    <div class="col-xs-12">
                      <input type="text" class="form-control required"
                             id="firstName"
                             #firstName="ngModel"
                             name="firstName"
                             placeholder="First Name"
                             [(ngModel)]="instructor.firstName"
                             required
                             autoFocus
                             noWhiteSpace
                             maxlength="255">
                      <p class="help-block"
                         *ngIf="firstName.invalid && (editInstructorForm.submitted || firstName.dirty)">
                        <span *ngIf="firstName.errors?.required">First Name is required</span>
                      </p>
                    </div>
                  </div>
                </div>

                <div class="col-xs-12 col-sm-4">
                  <div class="form-group"
                       [ngClass]="{'has-error': lastName.invalid && (editInstructorForm.submitted || lastName.dirty)}">

                    <div class="col-xs-12">
                      <label for="lastName" class="">Last Name <span class="sr-only">(Required)</span></label>
                    </div>

                    <div class="col-xs-12">
                      <input type="text" class="form-control required"
                             id="lastName"
                             name="lastName"
                             #lastName='ngModel'
                             placeholder="Last Name"
                             required
                             noWhiteSpace
                             maxlength="255"
                             [(ngModel)]="instructor.lastName">
                      <p class="help-block"
                         *ngIf="lastName.invalid && (editInstructorForm.submitted || lastName.dirty)">
                        <span *ngIf="lastName.errors?.required">Last Name is required</span>
                      </p>
                    </div>
                  </div>
                </div>

                <div class="col-xs-12 col-sm-4">
                  <div class="form-group">
                    <div class="col-xs-12 standard-margin-top no-margin-top-xs">
                      <div class="checkbox">
                        <label for="activeInstructor">
                          <input name="activeInstructor" id="activeInstructor" type="checkbox"
                                 [(ngModel)]="instructor.active">
                          <strong>Active</strong>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>

              </div>

              <div class="form-group"
                   [ngClass]="{'has-error': instructorProfile.invalid && (editInstructorForm.submitted || instructorProfile.dirty)}">
                <div class="col-xs-12">
                  <label for="instructorProfile" class="">Profile <span
                    class="sr-only">(Required)</span></label>
                  <markdown-editor-component
                    [id]="'instructorProfile'" name="instructorProfile"
                    #instructorProfile="ngModel"
                    [ngClass]="{'disabled-text pointer-events-none': !!saveSubscriber}"
                    [(ngModel)]="instructor.profile" [rows]="10"
                    noWhiteSpace
                    [required]="true">
                  </markdown-editor-component>
                  <p class="help-block"
                     *ngIf="instructorProfile.invalid && (editInstructorForm.submitted || instructorProfile.dirty)">
                    <span [hidden]="!instructorProfile.errors?.required">Profile is required</span>
                  </p>
                </div>
              </div>
            </fieldset>
          </div>
        </div>
      </div>
    </fieldset>
  </form>
</div>
