<div class="panel panel-inline-edit" dnd-nodrag>
  <div class="panel-heading clearfix">
    <fieldset [disabled]="fileUploadProgress" class="no-margin-bottom">
      <span>Edit question answer</span>
      <button [disabled]="!editQuestionMatchingForm.valid" (click)="submit(editQuestionMatchingForm)" type="button" title="Save an answer" class="pull-right btn btn-success btn-sm"><span class="fa fa-check"></span></button>
      <button (click)="cancel()" type="button" title="Cancel question answer edit" class="pull-right btn btn-danger btn-sm"><span class="fa fa-times"></span></button>
    </fieldset>

  </div>
  <form class="panel-body" name="editQuestionMatchingForm" null-form #editQuestionMatchingForm="ngForm">
    <p class="pull-right-sm" *ngIf="katexMessageVisible"><a href="https://khan.github.io/KaTeX/function-support.html" target="_blank">KaTeX</a> is enabled.
      Use the dollar sign to indicate the start and end of a formula $...$.
    </p>
    <!--Matching Text-->
    <div class="form-group">
      <div class="col-xs-12" [ngClass]="{'has-error': matchingText.invalid && matchingText.dirty}">
        <markdown-editor-component [(ngModel)]="answerDraft.text" name="matchingText"
                                   [required]="true" [rows]="5"  #matchingText="ngModel"
                                   [disableAttachments]="true" [maxlength]="1000" noWhiteSpace
                                   [id]="'matchingText'"
                                   autoFocus ></markdown-editor-component>
        <p class="help-block" *ngIf="matchingText.invalid && matchingText.dirty">
          <span *ngIf="matchingText.errors?.required">Text is required.</span>
        </p>
      </div>
    </div>

    <!--Matching Image-->
    <div class="form-group">
      <div class="col-sm-12">
        <div *ngIf="answerDraft.image?.id" class="row">
          <div class="col-sm-4 thumbnail-control" style="height: 100px">
            <img style="max-height: 100%; max-width: 100%" class="thumbnail standard-margin-bottom"
                 alt="Answer image"
                 src="/a/media/file/{{answerDraft.image.id}}/?rnd={{answerDraft.image.rnd}}">
          </div>

          <div class="col-sm-4 thumbnail-control">
            <button type="button" class="btn btn-danger" (click)="answerDraft.image = null" title="Remove image">
              <span class="fa-solid fa-trash-can"></span> Remove
            </button>
          </div>
        </div>
        <div *ngIf="!answerDraft.image?.id" >
          <image-upload [(tmpFile)]="answerImage" [options]="fileUploadOptions" [simpleView]="true"> </image-upload>
        </div>
      </div>
    </div>

    <p class="pull-right-sm" *ngIf="katexMessageVisible"><a href="https://khan.github.io/KaTeX/function-support.html" target="_blank">KaTeX</a> is enabled.
      Use the dollar sign to indicate the start and end of a formula $....
    </p>
    <!--Matching Answer Text-->
    <div class="form-group">
      <div class="col-xs-12" [ngClass]="{'has-error': matchingAnswerText.invalid && matchingAnswerText.dirty}">

        <markdown-editor-component [(ngModel)]="answerDraft.answerText" name="matchingAnswerText"
                                   [required]="true" [rows]="5"  #matchingAnswerText="ngModel"
                                   [disableAttachments]="true" [maxlength]="1000" noWhiteSpace
                                   [id]="'matchingAnswerText'"></markdown-editor-component>
        <p class="help-block" *ngIf="matchingAnswerText.invalid && matchingAnswerText.dirty">
          <span *ngIf="matchingAnswerText.errors?.required">Answer text is required.</span>
        </p>
      </div>
    </div>

  </form>
</div>
